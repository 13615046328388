import React from 'react';
import styled from 'styled-components';
import DesignPhaseIcon from '../icons/DesignPhaseIcon';
import PhaseCircle from './PhaseIconCircle';
import { RouteComponentProps } from 'react-router';

export const ScaledDesignPhaseIcon = styled(({ scale, ...otherProps }) => (
  <DesignPhaseIcon {...otherProps} />
))<{ scale: number }>`
  && {
    transform: scale(${props => props.scale});
  }
`;

interface DesignPhasesCircleProps extends RouteComponentProps {
  size: number;
  getCenterContent?: Function;
  iconScale: number;
  includeLog?: boolean;
  onCloseDrawer?: () => void;
}

export const phaseList = [
  'challenge',
  'research',
  'ideas',
  'prototype',
  'presentation',
  'reflection'
];

export default function DesignPhasesCircle(props: DesignPhasesCircleProps) {
  return (
    <PhaseCircle
      includeLog={props.includeLog}
      size={props.size}
      getCenterContent={props.getCenterContent}
      phaseList={phaseList}
      onCloseDrawer={props.onCloseDrawer}
      {...props}
    >
      {phaseList.map(phase => {
        return (
          <ScaledDesignPhaseIcon
            key={phase}
            fontSize="large"
            withColor
            designPhase={phase}
            scale={props.iconScale}
          />
        );
      })}
    </PhaseCircle>
  );
}
