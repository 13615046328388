import { Theme } from '@material-ui/core';
import { merge } from 'lodash';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { grey, red, blue, green, purple, orange, sand } from './palettes';
import { DesignPhases } from '../constants/DesignPhases';

const muiThemeOptions: ThemeOptions = {
  shape: {
    borderRadius: 15
  },
  palette: {
    common: { black: 'rgba(0, 0, 0, 1)', white: '#fff' },
    background: { paper: 'rgba(246, 245, 244, 1)', default: '#fff' },

    primary: {
      light: '#ffffff',
      main: '#000',
      dark: '#cccccc',
      contrastText: '#000000'
    },
    secondary: {
      light: '#ff6d82',
      main: red[400],
      dark: '#c5002e',
      contrastText: '#ffffff'
    },
    error: red,
    text: {
      primary: grey[900],
      secondary: grey[700],
      disabled: grey[500],
      hint: grey[700]
    }
  },
  typography: {
    allVariants: {
      color: grey[900]
    },
    h1: {
      fontFamily: "'Libre Baskerville', serif",
      letterSpacing: '-3px',
      fontSize: '108px'
    },
    h2: {
      fontFamily: "'Libre Baskerville', serif",
      letterSpacing: '-1.1px',
      fontSize: '60.38px'
    },
    h3: {
      fontFamily: "'Libre Baskerville', serif",
      letterSpacing: '0.2px',
      fontSize: '40.3px'
    },
    h4: {
      fontFamily: "'Libre Baskerville', serif",
      fontSize: '24.2px',
      fontWeight: 500
    },
    h5: {
      letterSpacing: '0.25px',
      fontWeight: 500,
      fontSize: '24.2px'
    },
    h6: {
      letterSpacing: '0.5px',
      fontWeight: 500,
      fontSize: '19.8px'
    },
    body1: {
      letterSpacing: '0.5px',
      fontSize: '16.1px'
    },
    body2: {
      letterSpacing: '0.25px',
      fontSize: '14.1px'
    },
    subtitle1: {
      letterSpacing: '0.15px',
      fontWeight: 500,
      fontSize: '16.1px'
    },
    subtitle2: {
      letterSpacing: '0.1px',
      fontWeight: 500,
      fontSize: '14px'
    },
    button: {
      fontWeight: 600,
      fontSize: '13.4px'
    },
    caption: {
      letterSpacing: '-0.2px',
      fontWeight: 500,
      fontSize: '12.1px'
    },

    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: 'transparent',
        opacity: 1
      },
      indicator: {
        height: 3
      }
    },
    MuiTab: {
      root: {
        paddingLeft: 6,
        paddingRight: 6,
        textTransform: 'none',
        marginRight: 40,
        minWidth: 0,
        lineHeight: 3.8,
        letterSpacing: -0.2,
        '@media (min-width: 0px)': {
          minWidth: 0
        },
        '&:hover': {
          backgroundColor: grey[200]
        },

        '&$selected': {
          color: red[500]
        }
      },
      textColorInherit: {
        color: grey[900],
        opacity: 1,
        fontSize: 13.4
      }
    },
    MuiDialog: {
      paperScrollPaper: {}
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center'
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#fff'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 10,
        backgroundColor: sand[200]
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 10
        }
      }
    },
    MuiTouchRipple: {
      root: {
        color: grey[900]
      }
    },
    MuiButton: {
      root: {
        borderRadius: '10px',
        padding: '10px 30px',
        fontWeight: 600,
        textTransform: 'none'
      },
      text: {
        color: red[500],
        background: 'none',
        margin: '10px 30px',
        '&:hover': {
          background: 'none',
          textDecoration: 'underline'
        }
      },
      containedPrimary: {
        backgroundColor: 'white',
        color: red[500]
      },
      contained: {
        border: `1px solid transparent`,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: red[500]
        },
        '&:selected': {
          backgroundColor: red[800]
        },
        backgroundColor: red[500],
        color: '#fff'
      },
      outlined: {
        border: `1px solid ${grey[900]}`,
        padding: '10px 30px',
        '&:hover': {
          border: `1px solid ${red[500]}`,
          color: red[500],
          background: 'none'
        }
      },
      outlinedSecondary: {
        border: `1px solid white`,
        padding: '10px 30px',
        color: 'white',
        '&:hover': {
          border: `1px solid ${grey[500]}`,
          color: 'white',
          background: 'none'
        }
      }
    }
  }
};

// interface ExtraThemeStylesOptions extends Partial{<{

// }>}

const extraThemeStyles = {
  designPhaseColors: {
    [DesignPhases.challenge]: red[500],
    [DesignPhases.research]: orange[500],
    [DesignPhases.ideas]: blue[500],
    [DesignPhases.prototype]: purple[500],
    [DesignPhases.presentation]: green[500],
    [DesignPhases.reflection]: blue[900]
  },
  footer: {
    color: grey[900]
  },
  courseTitle: {
    fontSize: 24.2,
    lineHeight: 1.16,
    fontWeight: 'bold',
    color: grey[900]
  },
  cardTitle: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.24,
    letterSpacing: -0.4,
    color: grey[900]
  },
  cardOverline: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 1.5,
    color: red[600]
  },

  extraPalette: {
    red,
    grey,
    blue,
    green,
    purple,
    orange,
    sand
  }
};

type ExtraThemeStylesTypes = typeof extraThemeStyles;

export interface ThemeInterface extends Theme, ExtraThemeStylesTypes {}

const theme = merge(muiThemeOptions, extraThemeStyles);

export default theme;
