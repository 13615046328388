import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DocButton = styled(Link)<{ selected?: boolean }>`
  background-color: ${props =>
    props.selected
      ? props.theme.extraPalette.red[400]
      : props.theme.extraPalette.sand[700]};
  border-radius: 10px;
  font-family: ${props => props.theme.typography.subtitle2};
  margin: ${props => props.theme.spacing(1)}px 0;
  padding: ${props => props.theme.spacing(2)}px;
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: ${props => props.theme.spacing(2)}px;
  box-shadow: ${props => (props.selected ? props.theme.shadows[4] : 'none')};
  font-weight: ${props => props.theme.typography.button.fontWeight};
  font-weight: ${props => props.theme.typography.button.fontWeight};
  font-size: ${props => props.theme.typography.button.fontSize};
  && {
    color: ${props =>
      props.selected
        ? props.theme.extraPalette.grey[50]
        : props.theme.extraPalette.grey[900]};
  }
  :hover {
    background-color: ${props =>
      props.selected
        ? props.theme.extraPalette.red[400]
        : props.theme.extraPalette.sand[900]};
  }
  hyphens: manual;
  white-space: pre-line;
`;

interface SideMenuButtonProps {
  to: string;
  selected?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

export default function SideMenuButton(props: SideMenuButtonProps) {
  return <DocButton {...props} />;
}
