import { Button, Grid, Typography, Box, Divider } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyClassesImg from '../../assets/banners/GUTF_Mine_klasser.png';
import MainActionMenu from '../../components/ActionMenu/MainActionMenu';
import { useCurrentUser } from '../../components/Auth/AuthProvider';
import Body, { Content } from '../../components/Body';
import MainMenu from '../../components/Header/components/MainMenu';
import Header from '../../components/Header/Header';
import HeaderCover from '../../components/HeaderCover';
import StyledLink from '../../components/StyledLink';
import { yellow, sand } from '../../style/palettes';
import { SelectClassesDialog } from '../Courses/Course/components/SelectClassesDialog';
import { getInstitutionsUsersCollectionRef } from '../../firestore/collections/Institutions/Users';
import { useUnicGroups } from '../../firestore/models/Institution/hooks';
import {
  getInstitutionsCoursesCollectionRef,
  CourseStatuses
} from '../../firestore/collections/Institutions/Courses';
import { firestore } from 'firebase/app';
import { MY_CLASSES_PATH, RoutePaths } from '../AppRouter';
import { Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components';
import MyClass from './MyClass';
import { ContentDescription } from '../Courses/Course/components/ContentTypography';

const ButtonHolder = styled(Grid)`
  padding-top: 24px;
  ${props => props.theme.breakpoints.up('md')} {
    justify-content: flex-end;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const ActiveItem = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    text-align: right;
  }
`;

const DraftItem = styled(Grid)`
  && {
    text-align: center;
    ${props => props.theme.breakpoints.down('xs')} {
      text-align: left;
    }
  }
`;

function MyClasses() {
  const user = useCurrentUser();
  const [isSelectDialogOpen, setSelectDialogOpen] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState<Array<any>>([]);

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const allGroups = useUnicGroups(user);
  // get the group list
  // get the user group list
  // filter group list

  // On mount

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }
      const uid = user.institutionUser.id;
      const usersCollectionRef = getInstitutionsUsersCollectionRef(
        user.institutionId
      );
      const courseRef = getInstitutionsCoursesCollectionRef(user.institutionId);

      const userDocRef = await usersCollectionRef.doc(uid).get();
      const data = userDocRef.data();
      if (!data || !data.unicGroups) {
        return;
      }

      if (!allGroups) {
        return;
      }

      const groups =
        allGroups &&
        allGroups
          .filter(g => data.unicGroups.indexOf(g.unicName) !== -1)
          .map(async g => {
            const endedSnap = await courseRef
              .where('unicGroups', 'array-contains', g.unicName)
              .where('status', '==', CourseStatuses.ended)
              .get();

            const activeSnap = await courseRef
              .where('unicGroups', 'array-contains', g.unicName)
              .where('status', '==', CourseStatuses.published)
              .get();

            const draftSnap = await courseRef
              .where('unicGroups', 'array-contains', g.unicName)
              .where('status', '==', CourseStatuses.draft)
              .where('ownerUID', '==', user.userDocRef.id)
              .get();

            return {
              ...g,
              ended: (endedSnap && endedSnap.size) || 0,
              active: (activeSnap && activeSnap.size) || 0,
              draft: (draftSnap && draftSnap.size) || 0
            };
          });

      setFilteredGroups(await Promise.all(groups));
    }
    fetchData();
  }, [user, allGroups]);

  async function handleAddClass(classNames: Array<string>) {
    const uid = user.institutionUser.id;
    const usersCollectionRef = getInstitutionsUsersCollectionRef(
      user.institutionId
    );
    const userDoc = await usersCollectionRef.doc(uid).get();
    await Promise.all(
      classNames.map(async c => {
        await userDoc.ref.update({
          unicGroups: firestore.FieldValue.arrayUnion(c)
        });
      })
    );
    setSelectDialogOpen(false);
    enqueueSnackbar(t('classes.class_added'), { variant: 'success' });
  }

  const filteredGroupIds = filteredGroups.map(group => {
    return group.id;
  });

  return (
    <>
      <Header>
        <MainMenu
          drawerTitle={t('drawer_titles.my_classes')}
          actionMenu={<MainActionMenu />}
        />
      </Header>
      <HeaderCover
        backgroundColorLeft={sand[600]}
        backgroundColorRight={yellow[500]}
        coverImageRef={MyClassesImg}
      />
      <Body>
        <Content>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="space-between"
          >
            <Grid item md={8} xs={12}>
              <Typography variant="h6" paragraph>
                {t('classes.assigned_title')}
              </Typography>
              <ContentDescription>{t('classes.body')}</ContentDescription>
            </Grid>
            <ButtonHolder item container md={12} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setSelectDialogOpen(true)}
              >
                {t('classes.add')}
              </Button>
            </ButtonHolder>
          </Grid>
          <Box height={18} />

          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 24 }}
          >
            {(filteredGroups && filteredGroups.length > 0 && (
              <>
                {filteredGroups.map(g => (
                  <Grid item key={g.id}>
                    <Grid container key={g.id}>
                      <Grid item xs={12} md={4}>
                        <Typography variant="h6" color="secondary">
                          <StyledLink to={`${MY_CLASSES_PATH}/${g.unicName}`}>
                            {g.unicName}
                          </StyledLink>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid container>
                          <Grid item xs={12} sm={4}>
                            <Typography>{`${t('classes.ended_courses', {
                              count: g.ended
                            })}`}</Typography>
                          </Grid>
                          <DraftItem item xs={12} sm={4}>
                            <Typography>
                              {' '}
                              {`${t('classes.published_courses', {
                                count: g.draft ? g.draft : 0
                              })}`}
                            </Typography>
                          </DraftItem>
                          <ActiveItem item xs={12} sm={4}>
                            <Typography>{`${t('classes.active_courses', {
                              count: g.active
                            })}`}</Typography>
                          </ActiveItem>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box height={20} />
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )) || <Grid item>{t('classes.assigned_empty')}</Grid>}
          </Grid>
        </Content>
      </Body>
      <SelectClassesDialog
        myClasses={filteredGroupIds}
        classes={[]}
        onClose={() => setSelectDialogOpen(false)}
        open={isSelectDialogOpen}
        onSave={handleAddClass}
        title={t('course.classes_and_groups.add_classes_dialog_title')}
      />
    </>
  );
}

export default function MyClassesRouter(props: RouteComponentProps) {
  return (
    <Switch>
      <Route exact path={RoutePaths.myClasses.overview} component={MyClasses} />
      <Route
        path={RoutePaths.myClasses.detail.toString()}
        component={MyClass}
      />
    </Switch>
  );
}
