import React from 'react';
import Footer from './Footer';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';

const ContentStyled = styled.div<{
  paddingTop: number;
  paddingBottom: number;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: ${props => props.theme.spacing(props.paddingTop)}px;
  padding-bottom: ${props => props.theme.spacing(props.paddingBottom)}px;
  ${props => props.theme.breakpoints.up('md')} {
    padding-top: ${props => props.theme.spacing(props.paddingTop)}px;
    padding-bottom: ${props => props.theme.spacing(props.paddingBottom)}px;
  }
  ${props => props.theme.breakpoints.up('lg')} {
    padding-top: ${props => props.theme.spacing(props.paddingTop * 2)}px;
    padding-bottom: ${props => props.theme.spacing(props.paddingBottom)}px;
  }
`;

/*
const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: ${props => props.theme.spacing(6)}px;
  padding-bottom: ${props => props.theme.spacing(12)}px;
`;
*/

export const MaxWidthCenteredContainer = styled.div`
  flex: 1;
  ${props => props.theme.breakpoints.up('lg')} {
    width: ${props => props.theme.breakpoints.values.lg}px;
    align-self: center;
  }
`;

interface ContentProps {
  children: React.ReactNode;
  maxWidthCenterContainer?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
}

export function Content({
  maxWidthCenterContainer = true,
  paddingTop = 6,
  paddingBottom = 12,
  children
}: ContentProps) {
  return (
    <ContentStyled paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {maxWidthCenterContainer ? <Container>{children}</Container> : children}
    </ContentStyled>
  );
}
interface BodyProps {
  children: React.ReactNode;
}

export default ({ children }: BodyProps) => {
  return (
    <>
      {children}
      <Footer />
    </>
  );
};
